import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/blogReferenceComponent/blogReferenceComponent.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/blogReferenceComponent/swiperComponent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/contactFormSection/form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/faq/faq.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/contactFormSection/contactFormSection.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/faq/faqItems.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/newsletterSection/form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/sliderComponent/swiperComponent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/button/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/heading/styles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/centralizedHeading/centralizedHeading.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/newsletterSection/newsletterSection.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/offersComponent/offersComponent.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/tilesComponent/tilesComponent.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/sliderComponent/sliderComponent.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_homepage/hero/hero.module.scss")